
import PrimarySection from './Primary'
import PeriodSection from './Period'
import TargetSection from './Target'
import SettingSection from './Setting'

export {
  PrimarySection,
  PeriodSection,
  TargetSection,
  SettingSection
}