import AdherentsSection from './Adherents'
import ConnectionSection from './Connection'
import CustomerBlacklistSection from './CustomerBlacklist'
import HistorySection from './History'
import OptionsSection from './Options'
import PrimarySection from './Primary'
import SecondarySection from './Secondary'
import ServiceBlacklistSection from './ServiceBlacklist'
import HeatmapBlacklist from './HeatmapBlacklist'

export {
  AdherentsSection,
  ConnectionSection,
  CustomerBlacklistSection,
  HeatmapBlacklist,
  HistorySection,
  OptionsSection,
  PrimarySection,
  SecondarySection,
  ServiceBlacklistSection,
}